.badge {
  @extend .uk-badge;
  font-family: $base-heading-font-family;
}

.badge--success {
  @extend .uk-badge-success;
}

.badge-link {
  color: #fff !important;
}
