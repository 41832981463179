.menu {
  font-family: $base-heading-font-family;
  a {
    border-bottom-color: $alpha-color !important;
  }
}

.menu-bar {
  border-right: solid 1px rgba(#000, 0.3);
  &::after {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    background: rgba(#000, 0.6);
    box-shadow: 0 0 5px 2px rgba(#000, 0.3);
    content: "";
  }
}

.menu-header {
  @include text-shadow;
  background-color: #444;
  font-size: 1.2em;
}

.menu-nav {
  border-bottom: solid 1px rgba(#000, 0.3);
}

.menu-link {
  border-top: solid 1px rgba(#000, 0.3);
}
