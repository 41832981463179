.product {
  @extend .ut-border-bottom;
}

.product-header {
  @extend .ut-border-bottom;
}

.product-image {
  margin: 1rem 0;
}

.product-notice {
  margin: -0.6rem 0;
}

.product-star {
  cursor: pointer;
  border-color: $alpha-color !important;
  &.fa-star {
    @include text-shadow;
    color: $second-color;
    -webkit-text-stroke: 1px black;
  }
}

.product-price {
  float: right;
  font-family: $base-heading-font-family;
  font-size: 0.9em;
  font-weight: 400;
  .fa-ruble {
    font-size: 0.9em;
  }
}

.product-table {
  height: 6rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  th, td {
    padding: 0 0.5rem;
  }
  th {
    text-align: left;
  }
}

.product-table-help {
  border-color: $alpha-color !important;
  opacity: 0.4;
  font-size: 0.9em;
  &:hover {
    opacity: 1;
  }
}

.product-thumbnail {
  height: 6rem;
  width: auto;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  border: solid 1px darken($grey-color, 10%);
}

.product-thumbnail--model {
  border-color: lighten($grey-color, 20%);
}
