.mainnav {
  @extend .uk-flex;
  background: linear-gradient(to bottom, $first-color, lighten($first-color, 10%));
  font-family: $base-heading-font-family;
}

.mainnav-toggle {
  @extend .uk-visible-small;
  @extend .ut-hidden-print;
  @extend .uk-flex;
  @extend .uk-flex-middle;
  margin-left: 2rem;
}

.mainnav-toggle-link {
  font-size: 1.4rem;
  color: #fff;
  border-color: $alpha-color !important;
  &:hover {
    color: #000;
  }
}

.mainnav-logo {
  @extend .uk-flex;
  @extend .uk-flex-middle;
  @include text-shadow;
  margin-left: 1rem;
  font-size: 2rem;
}

.mainnav-logo-link {
  margin: 0.6rem 0;
  line-height: 1;
  color: #fff !important;
}

.mainnav-list {
  @extend .uk-list;
  @extend .uk-margin-remove;
  @extend .ut-hidden-print;
  @extend .uk-hidden-small;
  @extend .uk-flex;
  margin-left: 1rem !important;
  counter-reset: ol-counter;
}

.mainnav-item {
  @extend .uk-flex;
  @extend .uk-flex-middle;
  z-index: 10;
  margin-bottom: -1px;
  margin-left: 1rem;
  border-style: solid;
  border-width: 0 1px ;
  border-color: $alpha-color;
  font-weight: 700;
  line-height: 1.1;
}

.mainnav-item--active {
  border-color: $first-color;
  background-color: lighten($first-color, 15%);
}

.mainnav-link {
  position: relative;
  margin: 0 0.5rem;
  padding-left: 1.5rem;
  color: #fff !important;
  &::before {
    @extend .uk-flex;
    @extend .uk-flex-middle;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    font-size: 2.5rem;
    line-height: 1;
    content: counter(ol-counter);
    counter-increment: ol-counter;
  }
}

.mainnav-stars {
  display: none;
  margin-right: 0.3rem;
  color: #fff !important;
  border-color: $alpha-color !important;
}

.mainnav-stars-layout {
  @include text-shadow;
  -webkit-text-stroke: 1px black;
}

.mainnav-stars-count {
  font-family: $base-heading-font-family;
  color: black;
  &:hover {
    color: $second-color;
  }
}
