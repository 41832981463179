.contacts {
  @extend .uk-margin-remove;
  @extend .uk-flex;
  @extend .uk-flex-middle;
  font-family: $base-heading-font-family;
  font-size: 1.1em;
  line-height: 1.25;
  color: #222;
}

.contacts--default {
  @extend .ut-hidden-print;
  @extend .uk-hidden-small;
  @extend .uk-border-rounded;
  @include box-shadow--contrast;
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  bottom: 0.3rem;
  border: solid 1px $first-color;
  background-color: $base-body-background;
  text-align: center;
}

.contacts-list--default {
  @extend .uk-list;
  @extend .uk-margin-remove;
  margin: 0 0.5rem !important;
}

.contacts--compact {
  @extend .uk-visible-small;
  @include box-shadow;
  background-color: $second-color;
  border-top: solid 1px $first-color;
  border-bottom: solid 1px darken($second-color, 20%);
  color: #fff;
}

.contacts-list--compact {
  @extend .uk-list;
  @extend .uk-margin-remove;
  @extend .uk-container;
  margin: 0.3rem 0 !important;
  & > * {
    float: left;
    margin-right: 1rem;
  }
}

.contacts-phone {
  font-size: 1.2rem;
  font-weight: 700;
}

.contacts-time {
  font-family: $base-body-font-family;
  font-size: 0.85rem;
}
