// General
$first-color: #884c27;
$second-color: #5782d9;
$third-color: #5da62a;
$grey-color: #777;
$alpha-color: rgba(#fff, 0);

// Uikit (base)
$base-body-background:                           #fff !default;
$base-body-font-family:                          "Open Sans", Arial, sans-serif;
$base-body-font-weight:                          300;
$base-body-font-size:                            1rem;
$base-body-line-height:                          1.4;
$base-body-color:                                #444;

$base-link-color:                                $second-color;
$base-link-text-decoration:                      none !default;
$base-link-hover-color:                          $second-color;
$base-link-hover-text-decoration:                underline !default;

$base-code-color:                                #D05 !default;
$base-code-font-size:                            (12rem/14) !default;
$base-code-font-family:                          Consolas, monospace, serif !default;
$base-em-color:                                  $base-body-color;
$base-ins-background:                            #ffa !default;
$base-ins-color:                                 $base-body-color;
$base-mark-background:                           #ffa !default;
$base-mark-color:                                $base-body-color;
$base-quote-font-style:                          italic !default;

$base-margin-vertical:                           (15rem/14) !default;

$base-heading-font-family:                       "Ubuntu Condensed", Arial, sans-serif;
$base-heading-font-weight:                       700;
$base-heading-color:                             #000;
$base-heading-text-transform:                    none !default;
$base-heading-margin-top:                        (25rem/14) !default;
$base-h1-font-size:                              2.074rem;
$base-h1-line-height:                            1;
$base-h2-font-size:                              1.728rem;
$base-h2-line-height:                            1;
$base-h3-font-size:                              1.44rem;
$base-h3-line-height:                            1;
$base-h4-font-size:                              1.2rem;
$base-h4-line-height:                            1;
$base-h5-font-size:                              1rem;
$base-h5-line-height:                            1;
$base-h6-font-size:                              0.833rem;
$base-h6-line-height:                            1;

$base-list-padding-left:                         (30rem/14) !default;

$base-hr-margin-vertical:                        $base-margin-vertical !default;
$base-hr-border:                                 #ddd !default;
$base-hr-border-width:                           (1rem/14) !default;

$base-blockquote-padding-left:                   (15rem/14) !default;
$base-blockquote-border:                         #ddd !default;
$base-blockquote-border-width:                   (5rem/14) !default;
$base-blockquote-font-size:                      (16rem/14) !default;
$base-blockquote-line-height:                    (22rem/14) !default;
$base-blockquote-font-style:                     italic !default;

$base-pre-padding:                               (10rem/14) !default;
$base-pre-background:                            #f5f5f5 !default;
$base-pre-color:                                 #555 !default;
$base-pre-font-size:                             (12rem/14) !default;
$base-pre-line-height:                           (18rem/14) !default;
$base-pre-font-family:                           $base-code-font-family !default;

$base-selection-background:                      #39f !default;
$base-selection-color:                           #fff !default;

// Uikit (article)
$article-margin-top:                             (25rem/14) !default;

$article-title-font-size:                        (36rem/14) !default;
$article-title-line-height:                      (42rem/14) !default;
$article-title-font-weight:                      normal !default;
$article-title-text-transform:                   none !default;

$article-meta-font-size:                         (12rem/14) !default;
$article-meta-line-height:                       (18rem/14) !default;
$article-meta-color:                             #999 !default;

$article-lead-color:                             #444 !default;
$article-lead-font-size:                         (18rem/14) !default;
$article-lead-line-height:                       (24rem/14) !default;
$article-lead-font-weight:                       normal !default;

$article-divider-margin:                         (25rem/14) !default;
$article-divider-border:                         #ddd !default;

// Uikit (badge)
$badge-background:                               $second-color;
$badge-padding-horizontal:                       (5rem/14) !default;
$badge-font-size:                                (10rem/14) !default;
$badge-font-weight:                              bold !default;
$badge-line-height:                              (14rem/14) !default;
$badge-color:                                    #fff !default;
$badge-text-transform:                           none !default;
$badge-hover-color:                              #fff !default;

$badge-notification-font-size:                   (12rem/14) !default;
$badge-notification-line-height:                 (18rem/14) !default;

$badge-success-background:                       $third-color;
$badge-warning-background:                       #faa732 !default;
$badge-danger-background:                        #da314b !default;

// Uikit (block)
$block-padding-vertical:                         (20rem/14) !default;
$block-padding-vertical-large:                   (50rem/14) !default;

$block-large-padding-vertical:                   $block-padding-vertical !default;
$block-large-padding-vertical-medium:            $block-padding-vertical-large !default;
$block-large-padding-vertical-large:             (100rem/14) !default;

$block-default-background:                       #fff !default;
$block-muted-background:                         #f9f9f9 !default;
$block-primary-background:                       #00a8e6 !default;
$block-secondary-background:                     #222 !default;

// Uikit (breadcrumb)
$breadcrumb-font-size:                           0.9rem;

$breadcrumb-divider:                             "/" !default;
$breadcrumb-divider-margin:                      0.4rem;
$breadcrumb-disabled-color:                      $grey-color;

// Uikit (button)
$button-height:                                  (30rem/14) !default;
$button-mini-height:                             (20rem/14) !default;
$button-small-height:                            (25rem/14) !default;
$button-large-height:                            3rem;

$button-line-height:                             (30rem/14) !default;
$button-mini-line-height:                        (20rem/14) !default;
$button-small-line-height:                       (25rem/14) !default;
$button-large-line-height:                       1.5;

$button-mini-font-size:                          (11rem/14) !default;
$button-small-font-size:                         (12rem/14) !default;
$button-large-font-size:                         1.75rem;

$button-padding-horizontal:                      (12rem/14) !default;
$button-mini-padding-horizontal:                 (6rem/14) !default;
$button-small-padding-horizontal:                (10rem/14) !default;
$button-large-padding-horizontal:                (15rem/14) !default;

$button-font-size:                               1rem !default;
$button-background:                              #eee !default;
$button-color:                                   #444 !default;
$button-hover-background:                        #f5f5f5 !default;
$button-hover-color:                             #444 !default;
$button-active-background:                       #ddd !default;
$button-active-color:                            #444 !default;

$button-primary-background:                      $second-color;
$button-primary-color:                           #fff !default;
$button-primary-hover-background:                lighten($second-color, 10%);
$button-primary-hover-color:                     #fff !default;
$button-primary-active-background:               darken($second-color, 10%);
$button-primary-active-color:                    #fff !default;

$button-success-background:                      $third-color;
$button-success-color:                           #fff !default;
$button-success-hover-background:                lighten($third-color, 10%);
$button-success-hover-color:                     #fff !default;
$button-success-active-background:               darken($third-color, 10%);
$button-success-active-color:                    #fff !default;

$button-danger-background:                       #da314b !default;
$button-danger-color:                            #fff !default;
$button-danger-hover-background:                 #e4354f !default;
$button-danger-hover-color:                      #fff !default;
$button-danger-active-background:                #c91032 !default;
$button-danger-active-color:                     #fff !default;

$button-disabled-background:                     #f5f5f5 !default;
$button-disabled-color:                          #999 !default;

$button-link-color:                              #07D !default;
$button-link-hover-color:                        #059 !default;
$button-link-hover-text-decoration:              underline !default;
$button-link-disabled-color:                     #999 !default;

// Uikit (close)
$close-size:                                     (20rem/14) !default;

$close-alt-padding:                              (2rem/14) !default;
$close-alt-background:                           #eee !default;

// Uikit (comment)
$comment-header-margin-bottom:                   (15rem/14) !default;

$comment-avatar-margin-right:                    (15rem/14) !default;

$comment-title-margin-top:                       (5rem/14) !default;
$comment-title-font-size:                        (16rem/14) !default;
$comment-title-line-height:                      (22rem/14) !default;

$comment-meta-margin-top:                        (2rem/14) !default;
$comment-meta-color:                             #999 !default;
$comment-meta-font-size:                         (11rem/14) !default;
$comment-meta-line-height:                       (16rem/14) !default;

$comment-list-margin-top:                        (15rem/14) !default;
$comment-list-padding-left:                      (100rem/14) !default;

// Uikit (contrast)
$contrast-color:                                 #fff !default;
$contrast-inverted-color:                        #444 !default;

// Uikit (description-list)
$description-list-horizontal-width:              (160rem/14) !default;
$description-list-horizontal-margin-left:        (180rem/14) !default;

$description-list-line-margin-top:               (5rem/14) !default;
$description-list-line-border-width:             (1rem/14) !default;
$description-list-line-border:                   #ddd !default;
$description-list-line-color:                    #999 !default;

// Uikit (dropdown)
$dropdown-z-index:                               970 !default;
$dropdown-width:                                 (200rem/14) !default;
$dropdown-margin-top:                            (5rem/14) !default;
$dropdown-padding:                               (15rem/14) !default;
$dropdown-background:                            #f5f5f5 !default;
$dropdown-color:                                 #444 !default;
$dropdown-font-size:                             1rem !default;
$dropdown-animation:                             uk-fade !default;

$dropdown-divider-border-width:                  (1rem/14) !default;
$dropdown-divider-border:                        #ddd !default;

$dropdown-small-padding:                         (5rem/14) !default;

$dropdown-navbar-margin:                         0 !default;
$dropdown-navbar-background:                     #f5f5f5 !default;
$dropdown-navbar-color:                          #444 !default;
$dropdown-navbar-animation:                      uk-slide-top-fixed !default;

$dropdown-scrollable-height:                     (200rem/14) !default;

// Uikit (form)
// TODO: hardcoded pixel in uikit codebase
// $form-height:                                    (30rem/14) !default;
// $form-padding:                                   (4rem/14) (6rem/14) !default;
// $form-border:                                    #ddd !default;
// $form-border-width:                              (1rem/14) !default;
// $form-background:                                #fff !default;
// $form-color:                                     #444 !default;
//
// $form-focus-border:                              #99baca !default;
// $form-focus-background:                          #f5fbfe !default;
// $form-focus-color:                               #444 !default;
//
// $form-disabled-border:                           #ddd !default;
// $form-disabled-background:                       #f5f5f5 !default;
// $form-disabled-color:                            #999 !default;
//
// $form-placeholder-color:                         #999 !default;
//
// $form-gutter:                                    (15rem/14) !default;
//
// $form-legend-border:                             #ddd !default;
// $form-legend-border-width:                       (1rem/14) !default;
// $form-legend-font-size:                          (18rem/14) !default;
// $form-legend-line-height:                        (30rem/14) !default;
//
// $form-small-height:                              (25rem/14) !default;
// $form-large-height:                              (40rem/14) !default;
// $form-small-padding:                             (3rem/14) (3rem/14) !default;
// $form-large-padding:                             (8rem/14) (6rem/14) !default;
// $form-small-font-size:                           (12rem/14) !default;
// $form-large-font-size:                           (16rem/14) !default;
//
// $form-danger-border:                             #dc8d99 !default;
// $form-danger-background:                         #fff7f8 !default;
// $form-danger-color:                              #d85030 !default;
//
// $form-success-border:                            #8ec73b !default;
// $form-success-background:                        #fafff2 !default;
// $form-success-color:                             #659f13 !default;
//
// $form-blank-border:                              #ddd !default;
// $form-blank-border-style:                        dashed !default;
//
// $form-mini-width:                                (40rem/14) !default;
// $form-small-width:                               (130rem/14) !default;
// $form-medium-width:                              (200rem/14) !default;
// $form-large-width:                               (500rem/14) !default;
//
// $form-help-inline-margin-left:                   (10rem/14) !default;
// $form-help-block-margin-top:                     (5rem/14) !default;
//
// $form-controls-condensed-margin-vertical:        (5rem/14) !default;
//
// $form-stacked-margin-bottom:                     (5rem/14) !default;
// $form-stacked-font-weight:                       bold !default;
//
// $form-horizontal-label-width:                    (200rem/14) !default;
// $form-horizontal-label-margin-top:               (5rem/14) !default;
// $form-horizontal-controls-margin-left:           (215rem/14) !default;
// $form-horizontal-controls-text-padding-top:      (5rem/14) !default;
//
// $form-icon-width:                                (30rem/14) !default;
// $form-icon-font-size:                            (14rem/14) !default;
// $form-icon-color:                                #999 !default;

// Uikit (grid)
$grid-gutter-horizontal:                         (25rem/14) !default;
$grid-gutter-vertical:                           (25rem/14) !default;

$grid-gutter-large-horizontal:                   (35rem/14) !default;
$grid-gutter-large-vertical:                     (35rem/14) !default;

$grid-gutter-small-horizontal:                   (10rem/14) !default;
$grid-gutter-small-vertical:                     (10rem/14) !default;

$grid-divider-border:                            #ddd !default;
$grid-divider-border-width:                      (1rem/14) !default;

// Uikit (icon)
$icon-font-path:                                 "//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts";

$icon-small-font-size:                           150% !default;
$icon-medium-font-size:                          200% !default;
$icon-large-font-size:                           250% !default;

$icon-small-vertical-align:                      -10% !default;
$icon-medium-vertical-align:                     -16% !default;
$icon-large-vertical-align:                      -22% !default;

$icon-hover-color:                               #999 !default;
$icon-hover-hover-color:                         #444 !default;

$icon-button-width:                              (35rem/14) !default;
$icon-button-height:                             $icon-button-width !default;
$icon-button-border-radius:                      100% !default;
$icon-button-background:                         #eee !default;
$icon-button-font-size:                          round(($icon-button-width * 0.5)) !default;
$icon-button-color:                              #444 !default;

$icon-button-hover-background:                   #f5f5f5 !default;
$icon-button-hover-color:                        #444 !default;

$icon-button-active-background:                  #ddd !default;
$icon-button-active-color:                       #444 !default;

// Uikit (list)
$list-nested-padding-left:                       (20rem/14) !default;

$list-line-margin-top:                           (5rem/14) !default;
$list-line-border:                               #ddd !default;
$list-line-border-width:                         (1rem/14) !default;

$list-striped-padding-vertical:                  (5rem/14) !default;
$list-striped-padding-horizontal:                (5rem/14) !default;
$list-striped-background:                        #f5f5f5 !default;

$list-space-margin-top:                          (10rem/14) !default;

// Uikit (modal)
$modal-z-index:                                  1010 !default;
$modal-background:                               rgba(0,0,0,0.6) !default;

$modal-dialog-margin-vertical:                   (50rem/14) !default;
$modal-dialog-padding:                           (20rem/14) !default;
$modal-dialog-width:                             (600rem/14) !default;
$modal-dialog-background:                        #fff !default;

$modal-dialog-large-width:                       (930rem/14) !default;
$modal-dialog-large-width-large:                 (1130rem/14) !default;

$modal-header-margin-bottom:                     (15rem/14) !default;

$modal-footer-margin-top:                        (15rem/14) !default;

$modal-caption-margin-bottom:                    -(10rem/14) !default;
$modal-caption-color:                            #fff !default;

// Uikit (nav)
$nav-padding-vertical:                           (5rem/14) !default;
$nav-padding-horizontal:                         (15rem/14) !default;

$nav-header-padding-vertical:                    $nav-padding-vertical !default;
$nav-header-padding-horizontal:                  $nav-padding-horizontal !default;
$nav-header-font-size:                           (12rem/14) !default;
$nav-header-font-weight:                         bold !default;
$nav-header-text-transform:                      uppercase !default;
$nav-header-margin-top:                          (15rem/14) !default;

$nav-divider-margin-vertical:                    (9rem/14) !default;
$nav-divider-margin-horizontal:                  $nav-padding-horizontal !default;

$nav-subtitle-font-size:                         (12rem/14) !default;
$nav-subtitle-line-height:                       (18rem/14) !default;

$nav-nested-padding-left:                        (15rem/14) !default;
$nav-nested-padding-vertical:                    (2rem/14) !default;
$nav-nested-padding-horizontal:                  0 !default;

$nav-sub-padding-vertical:                       (5rem/14) !default;
$nav-sub-padding-left:                           $nav-padding-horizontal !default;

$nav-parent-icon:                                "\f104" !default;
$nav-parent-open-icon:                           "\f107" !default;
$nav-parent-icon-width:                          (20rem/14) !default;
$nav-parent-icon-margin-right:                   -(10rem/14) !default;

$nav-side-color:                                 #444 !default;
$nav-side-hover-background:                      rgba(0,0,0,0.05) !default;
$nav-side-hover-color:                           #444 !default;
$nav-side-active-background:                     #00a8e6 !default;
$nav-side-active-color:                          #fff !default;
$nav-side-header-color:                          #444 !default;
$nav-side-divider-border:                        #ddd !default;
$nav-side-divider-border-width:                  (1rem/14) !default;
$nav-side-nested-color:                          #07D !default;
$nav-side-nested-hover-color:                    #059 !default;

$nav-dropdown-color:                             #444 !default;
$nav-dropdown-hover-background:                  #00a8e6 !default;
$nav-dropdown-hover-color:                       #fff !default;
$nav-dropdown-header-color:                      #999 !default;
$nav-dropdown-divider-border:                    #ddd !default;
$nav-dropdown-divider-border-width:              (1rem/14) !default;
$nav-dropdown-nested-color:                      #07D !default;
$nav-dropdown-nested-hover-color:                #059 !default;

$nav-navbar-color:                               #444 !default;
$nav-navbar-hover-background:                    #00a8e6 !default;
$nav-navbar-hover-color:                         #fff !default;
$nav-navbar-header-color:                        #999 !default;
$nav-navbar-divider-border:                      #ddd !default;
$nav-navbar-divider-border-width:                (1rem/14) !default;
$nav-navbar-nested-color:                        #07D !default;
$nav-navbar-nested-hover-color:                  #059 !default;

$nav-offcanvas-padding-vertical:                 (10rem/14) !default;
$nav-offcanvas-padding-horizontal:               (15rem/14) !default;
$nav-offcanvas-color:                            #ccc !default;
$nav-offcanvas-hover-background:                 #404040 !default;
$nav-offcanvas-hover-color:                      #fff !default;
$nav-offcanvas-active-background:                #1a1a1a !default;
$nav-offcanvas-active-color:                     #fff !default;
$nav-offcanvas-header-color:                     #777 !default;
$nav-offcanvas-divider-border:                   #1a1a1a !default;
$nav-offcanvas-divider-border-width:             (1rem/14) !default;
$nav-offcanvas-nested-color:                     #ccc !default;
$nav-offcanvas-nested-hover-color:               #fff !default;

// Uikit (navbar)
$navbar-background:                              #eee !default;
$navbar-color:                                   #333 !default;
$navbar-link-color:                              #07D !default;
$navbar-link-hover-color:                        #059 !default;

$navbar-nav-height:                              (40rem/14) !default;
$navbar-nav-line-height:                         $navbar-nav-height !default;
$navbar-nav-padding-horizontal:                  (15rem/14) !default;
$navbar-nav-color:                               #333 !default;
$navbar-nav-font-size:                           (14rem/14) !default;
$navbar-nav-font-weight:                         normal !default;
$navbar-nav-font-family:                         "Open Sans", Arial, sans-serif;
$navbar-nav-hover-background:                    #f5f5f5 !default;
$navbar-nav-hover-color:                         #333 !default;
$navbar-nav-onclick-background:                  #ddd !default;
$navbar-nav-onclick-color:                       #333 !default;
$navbar-nav-active-background:                   #f5f5f5 !default;
$navbar-nav-active-color:                        #333 !default;
$navbar-nav-subtitle-font-size:                  (10rem/14) !default;
$navbar-nav-subtitle-offset:                     (2rem/14) !default;

$navbar-brand-font-size:                         (18rem/14) !default;
$navbar-brand-color:                             #333 !default;
$navbar-brand-hover-color:                       #333 !default;

$navbar-toggle-font-size:                        (18rem/14) !default;
$navbar-toggle-color:                            #333 !default;
$navbar-toggle-hover-color:                      #333 !default;
$navbar-toggle-icon:                             "\f0c9" !default;
$navbar-toggle-icon-alt:                         "\f002" !default;

// Uikit (offcanvas)
$offcanvas-z-index:                              1000 !default;
$offcanvas-background:                           rgba(0,0,0,0.1) !default;

$offcanvas-bar-width:                            15rem;
$offcanvas-bar-background:                       #333 !default;

$offcanvas-panel-margin:                         (20rem/14) (15rem/14) !default;
$offcanvas-panel-color:                          #777 !default;
$offcanvas-panel-link-color:                     #ccc !default;
$offcanvas-panel-link-hover-color:               #fff !default;
$offcanvas-panel-title-color:                    $offcanvas-panel-link-color !default;

// Uikit (overlay)
$overlay-panel-padding:                          (20rem/14) !default;
$overlay-panel-color:                            #fff !default;

$overlay-panel-background:                       rgba(0,0,0,0.5) !default;

$overlay-icon:                                   "\f002" !default;
$overlay-icon-size:                              (50rem/14) !default;
$overlay-icon-color:                             #fff !default;

$overlay-fade-in-opacity:                        1 !default;
$overlay-fade-out-opacity:                       0.7 !default;

$overlay-scale-in-scale:                         1.1 !default;
$overlay-scale-out-scale:                        1 !default;

$overlay-spin-in-scale:                          1.1 !default;
$overlay-spin-out-scale:                         1 !default;
$overlay-spin-in-rotate:                         3deg !default;
$overlay-spin-out-rotate:                        0deg !default;

$overlay-panel-link-color:                       inherit !default;
$overlay-panel-link-text-decoration:             underline !default;

// Uikit (pagination)
$pagination-font-size:                           1rem !default;
$pagination-margin-left:                         (5rem/14) !default;
$pagination-padding-vertical:                    (3rem/14) !default;
$pagination-padding-horizontal:                  (5rem/14) !default;
$pagination-line-height:                         (20rem/14) !default;
$pagination-background:                          #eee !default;
$pagination-color:                               #444 !default;
$pagination-hover-background:                    #f5f5f5 !default;
$pagination-hover-color:                         #444 !default;
$pagination-onclick-background:                  #ddd !default;
$pagination-onclick-color:                       #444 !default;

$pagination-active-background:                   #00a8e6 !default;
$pagination-active-color:                        #fff !default;

$pagination-disabled-background:                 #f5f5f5 !default;
$pagination-disabled-color:                      #999 !default;

// Uikit (panel)
$panel-title-margin-bottom:                      (15rem/14) !default;
$panel-title-font-size:                          (18rem/14) !default;
$panel-title-line-height:                        (24rem/14) !default;
$panel-title-font-weight:                        normal !default;
$panel-title-color:                              #444 !default;
$panel-title-text-transform:                     none !default;

$panel-teaser-margin-bottom:                     $panel-title-margin-bottom !default;

$panel-box-padding:                              (15rem/14) !default;
$panel-box-background:                           #f5f5f5 !default;
$panel-box-color:                                #444 !default;
$panel-box-hover-color:                          $panel-box-color !default;
$panel-box-title-color:                          #444 !default;
$panel-box-badge-top:                            (10rem/14) !default;
$panel-box-badge-right:                          $panel-box-badge-top !default;
$panel-box-teaser-margin:                        -$panel-box-padding !default;

$panel-box-primary-background:                   #ebf7fd !default;
$panel-box-primary-color:                        #2d7091 !default;
$panel-box-primary-hover-color:                  $panel-box-primary-color !default;
$panel-box-primary-title-color:                  #2d7091 !default;

$panel-box-secondary-background:                 #eee !default;
$panel-box-secondary-color:                      #444 !default;
$panel-box-secondary-hover-color:                $panel-box-secondary-color !default;
$panel-box-secondary-title-color:                #444 !default;

$panel-hover-padding:                            (15rem/14) !default;
$panel-hover-color:                              #444 !default;
$panel-hover-hover-background:                   #f5f5f5 !default;
$panel-hover-hover-color:                        #444 !default;
$panel-hover-badge-top:                          (10rem/14) !default;
$panel-hover-badge-right:                        $panel-hover-badge-top !default;
$panel-hover-teaser-margin:                      -$panel-hover-padding !default;

$panel-header-title-padding:                     (10rem/14) !default;
$panel-header-title-border:                      #ddd !default;
$panel-header-title-border-width:                (1rem/14) !default;
$panel-header-title-color:                       #444 !default;

$panel-space-padding:                            (30rem/14) !default;

$panel-divider-gutter:                           (25rem/14) !default;
$panel-divider-gutter-large:                     (35rem/14) !default;
$panel-divider-border:                           #ddd !default;
$panel-divider-border-width:                     (1rem/14) !default;

// Ukikit (slidenav)
$slidenav-width:                                 60px !default;
$slidenav-height:                                $slidenav-width !default;
$slidenav-color:                                 rgba(50,50,50,0.4) !default;
$slidenav-font-size:                             60px !default;
$slidenav-line-height:                           $slidenav-height !default;
$slidenav-previous-icon:                         "\f104" !default;
$slidenav-next-icon:                             "\f105" !default;

$slidenav-hover-color:                           rgba(50,50,50,0.7) !default;

$slidenav-active-color:                          rgba(50,50,50,0.9) !default;

$slidenav-position-previous:                     20px !default;
$slidenav-position-next:                         $slidenav-position-previous !default;

$slidenav-contrast-color:                        rgba(255,255,255,0.5) !default;
$slidenav-contrast-hover-color:                  rgba(255,255,255,0.7) !default;
$slidenav-contrast-active-color:                 rgba(255,255,255,0.9) !default;

// Uikit (subnav)
$subnav-margin-horizontal:                       0.4rem;
$subnav-margin-vertical:                         $subnav-margin-horizontal !default;

$subnav-color:                                   $grey-color;
$subnav-hover-color:                             $grey-color;
$subnav-hover-text-decoration:                   underline;
$subnav-active-color:                            $grey-color;

$subnav-line-border-height:                      (10rem/14) !default;
$subnav-line-border:                             #ddd !default;
$subnav-line-border-width:                       (1rem/14) !default;

$subnav-pill-padding-vertical:                   (3rem/14) !default;
$subnav-pill-padding-horizontal:                 (9rem/14) !default;
$subnav-pill-hover-background:                   #eee !default;
$subnav-pill-hover-color:                        #444 !default;
$subnav-pill-active-background:                  #00a8e6 !default;
$subnav-pill-active-color:                       #fff !default;

$subnav-disabled-color:                          lighten($grey-color, 10%);

// Uikit (tab)
$tab-border:                                     #ddd !default;
$tab-border-width:                               (1rem/14) !default;
$tab-padding-horizontal:                         (12rem/14) !default;
$tab-padding-vertical:                           (8rem/14) !default;
$tab-padding-top:                                $tab-padding-vertical !default;
$tab-padding-bottom:                             $tab-padding-vertical !default;
$tab-margin-horizontal:                          (5rem/14) !default;
$tab-margin-vertical:                            (5rem/14) !default;
$tab-color:                                      $second-color;
$tab-hover-border:                               #f5f5f5 !default;
$tab-hover-background:                           #f5f5f5 !default;
$tab-hover-color:                                $second-color;
$tab-active-border:                              #ddd !default;
$tab-active-background:                          #fff !default;
$tab-active-color:                               #333;
$tab-disabled-color:                             #999 !default;

// Uikit (table)
$table-margin-vertical:                          1rem;
$table-padding-vertical:                         0.4rem;
$table-padding-horizontal:                       0.5rem;

$table-caption-color:                            #999 !default;
$table-caption-font-size:                        0.9rem;

$table-striped-background:                       lighten($second-color, 37.5%);

$table-condensed-padding-vertical:               0.2rem;
$table-condensed-padding-horizontal:             0.5rem;

$table-hover-background:                         lighten($second-color, 35%);

$table-active-background:                        $table-hover-background !default;

// Uikit (text)
$text-small-font-size:                           0.9rem;
$text-small-line-height:                         $base-body-line-height;
$text-large-font-size:                           1.1rem;
$text-large-line-height:                         $base-body-line-height;
$text-large-font-weight:                         normal !default;

$text-muted-color:                               #999 !default;
$text-primary-color:                             #2d7091 !default;
$text-success-color:                             #659f13 !default;
$text-warning-color:                             #e28327 !default;
$text-danger-color:                              #d85030 !default;
$text-contrast-color:                            #fff !default;

// Uikit (thumbnail)
$thumbnail-padding:                              (4rem/14) !default;
$thumbnail-border:                               #ddd !default;
$thumbnail-border-width:                         (1rem/14) !default;
$thumbnail-background:                           #fff !default;
$thumbnail-hover-border:                         #aaa !default;
$thumbnail-hover-background:                     #fff !default;

$thumbnail-caption-padding:                      $thumbnail-padding !default;
$thumbnail-caption-color:                        #444 !default;

$thumbnail-mini-width:                           (150rem/14) !default;
$thumbnail-small-width:                          (200rem/14) !default;
$thumbnail-medium-width:                         (300rem/14) !default;
$thumbnail-large-width:                          (400rem/14) !default;

// Uikit (thumbnav)
$thumbnav-margin-horizontal:                     (10rem/14) !default;
$thumbnav-margin-vertical:                       $thumbnav-margin-horizontal !default;

$thumbnav-background:                            #fff !default;

$thumbnav-opacity:                               0.7 !default;
$thumbnav-hover-opacity:                         1 !default;
$thumbnav-active-opacity:                        1 !default;

// Uikut (utility)
$utility-container-max-width:                    none;
$utility-container-padding-horizontal:           2rem;

$utility-container-large-max-width:              none;
$utility-container-large-padding-horizontal:     2rem;

$utility-align-horizontal:                       1rem;
$utility-align-vertical:                         1rem;

$utility-height-viewport-min-height:             40rem;

$utility-margin:                                 1rem;
$utility-margin-small:                           0.5rem;
$utility-margin-large:                           1.5rem;

$utility-border-rounded:                         0.4rem;

$utility-heading-large-small-font-size:          (36rem/14) !default;
$utility-heading-large-small-line-height:        (42rem/14) !default;
$utility-heading-large-font-size:                (52rem/14) !default;
$utility-heading-large-line-height:              (64rem/14) !default;

$utility-link-muted-color:                       #444 !default;
$utility-link-muted-hover-color:                 #444 !default;

$utility-scrollable-text-height:                 20rem;

$utility-scrollable-box-height:                  (170rem/14) !default;
$utility-scrollable-box-padding:                 (10rem/14) !default;
$utility-scrollable-box-border:                  #ddd !default;
$utility-scrollable-box-border-width:            (1rem/14) !default;

// Uikit (variables)
$breakpoint-small:                               500px;
$breakpoint-medium:                              1000px;
$breakpoint-large:                               2000px;
$breakpoint-xlarge:                              2500px;

$breakpoint-mini-max:                            ($breakpoint-small - 1) !default;
$breakpoint-small-max:                           ($breakpoint-medium - 1) !default;
$breakpoint-medium-max:                          ($breakpoint-large - 1) !default;
$breakpoint-large-max:                           ($breakpoint-xlarge - 1) !default;
