.category {
  @extend .uk-border-rounded;
  @include box-shadow;
  padding: 0 0.5rem;
  border: solid 1px $grey-color;
}

.category-heading {
  font-size: $base-h3-font-size;
}

.category-star {
  cursor: pointer;
  border-color: $alpha-color !important;
  &.fa-star {
    @include text-shadow;
    color: $second-color;
    -webkit-text-stroke: 1px black;
  }
}

.category-price {
  float: right;
  font-family: $base-heading-font-family;
  font-size: 0.9em;
  font-weight: 400;
  .fa-ruble {
    font-size: 0.9em;
  }
}

.category-content {
  @extend .uk-margin-bottom;
  @extend .uk-flex;
  @extend .uk-flex-top;
}

.category-image {
  @extend .uk-margin-small-right;
  @extend .uk-flex-item-none;
  width: 7rem;
  height: auto;
}

.category-descr {
  @extend .uk-flex-item-none;
}

.category-notice {
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}

.category-panel {
  @extend .uk-margin-top;
  @extend .uk-flex;
}

.category-items {
  @extend .uk-margin-left;
  @extend .uk-padding-remove;
  @extend .uk-flex-item-none;
  min-width: 5.2rem;
  margin-top: -0.6rem;
}

.category-item {
  margin-right: -1px;
  border-right: dotted 1px lighten($second-color, 10%);
  border-bottom: dotted 1px $alpha-color;
  white-space: nowrap;
  & > .category-item-link {
    font-family: $base-body-font-family;
    font-size: 0.9em;
    color: #000 !important;
  }
}

.category-item--active {
  border-bottom-color: lighten($second-color, 10%);
  & > .category-item-link {
    cursor: default;
    border-color: $alpha-color !important;
  }
}

.category-table {
  @extend .uk-flex-item-none;
  height: 6rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  th, td {
    padding: 0 0.5rem;
  }
  th {
    text-align: left;
  }
}

.category-table-help {
  border-color: $alpha-color !important;
  opacity: 0.4;
  font-size: 0.9em;
  &:hover {
    opacity: 1;
  }
}
