.categories {
  @extend .ut-border-bottom;
  @extend .uk-flex;
  @extend .uk-flex-wrap;
  padding: 0.5rem 1.5rem;
}

.categories-item {
  @extend .uk-flex;
  box-sizing: border-box;
  width: 100%;
  @media (min-width: 1350px) {
    width: 50%;
  }
  padding: 0.5rem;
  & > * {
    width: 100%;
  }
}
