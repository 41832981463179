.delivery {
  @extend .ut-hidden-print;
  @extend .uk-border-rounded;
  @include box-shadow--contrast;
  position: fixed;
  right: 0.3rem;
  bottom: 0.3rem;
  width: 15rem;
  border: solid 1px darken($third-color, 20%) !important;
  background-color: $third-color;
  font-family: $base-heading-font-family;
  font-size: 1.1em;
  line-height: 1.25;
  color: #fff;
  text-align: center;
}

.delivery-heading {
  @include text-shadow;
  margin: 0.5rem 0;
  color: #fff;
}

.delivery-content {
  margin: 0.5rem 0;
}

.delivery-close {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  line-height: 1;
}

.delivery-close-link {
  color: #fff !important;
  border-color: $alpha-color !important;
}
