.article {
  @extend .ut-border-bottom;
}

.article--styled {
  p:first-of-type {
    @extend .uk-text-large;
  }
  img {
    // TODO: rebase on uikit fully
    @extend .uk-border-rounded;
    float: left;
    margin-top: 0.3rem;
    margin-right: 1rem;
    margin-bottom: 0.3rem;
    border: solid 1px lighten($grey-color, 20%);
  }
}
