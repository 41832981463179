.feature {
  &:not(:last-child) {
    @extend .ut-border-bottom;
  }
}

.feature-container {
  @extend .uk-container;
  @extend .uk-margin-top;
  @extend .uk-margin-bottom;
  @extend .uk-flex;
  @extend .uk-flex-top;
}

.feature-figure {
  @extend .ut-border;
  @extend .ut-hidden-print;
  @extend .uk-hidden-small;
  @extend .uk-border-rounded;
  @extend .uk-flex-item-none;
  @extend .uk-overlay;
  margin-right: 0.5rem;
}

.feature-image {
  width: 16rem;
  height: 13rem;
}

.feature-caption {
  @extend .uk-overlay-panel;
  @extend .uk-overlay-top;
  @extend .ut-caption;
}
