.subnav {
  @extend .ut-hidden-print;
  @extend .uk-hidden-small;
  @include box-shadow;
  border-top: solid 1px $first-color;
  border-bottom: solid 1px lighten($first-color, 5%);
  background-color: lighten($first-color, 15%);
  font-family: $base-heading-font-family;
}

.subnav-list {
  @extend .uk-list;
  @extend .uk-margin-remove;
  @extend .uk-flex;
  margin-left: 0.8rem !important;
}

.subnav-item {
  margin: 0.4rem 0;
  margin-left: 0.2rem;
  border: solid 1px rgba(#fff, 0);
  font-weight: 400;
}

.subnav-item--active {
  @extend .uk-border-rounded;
  @include box-shadow--contrast;
  border-color: $first-color;
  background-color: $base-body-background;
  font-weight: 700;
}

.subnav-link {
  margin: 0 1rem;
  line-height: 1.2;
  letter-spacing: 0.05em;
  white-space: nowrap;
  color: #000 !important;
}
