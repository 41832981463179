.hero {
  @extend .ut-border-bottom;
  @media (min-width: $breakpoint-medium) {
    background-image: url("../images/background.jpg");
  }
}

.hero-heading {
  margin-top: 1rem;
}

.hero-container {
  @extend .uk-container;
  @extend .uk-margin-bottom;
}

.hero-content {
  @extend .uk-flex;
}

.hero-figure {
  @extend .ut-hidden-print;
  @extend .uk-hidden-small;
  @extend .uk-margin-remove;
  @extend .uk-padding-remove;
  @extend .uk-border-rounded;
  @extend .uk-flex-item-none;
  @extend .uk-width-medium-2-5;
  position: relative;
  overflow: hidden;
  border: solid 1px #000;
}

.hero-image {
  position: absolute;
  height: 100%;
  width: 100%;
}

.hero-caption {
  @include text-shadow;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem 1rem;
  border-top: solid 1px #000;
  background-color: rgba(#000, 0.4);
  font-family: $base-heading-font-family;
  font-size: 1.33em;
  line-height: 1;
  color: rgba(#fff, 1);
}

.hero-link {
  color: rgba(#fff, 1) !important;
}

.hero-price {
  float: right;
  color: rgba(#fff,1);
}

.hero-descr {
  @extend .uk-width-medium-3-5;
  // TODO: move to figure!
  margin-left: 0.5rem;
}

.hero-text {
  @extend .uk-text-large;
  margin-bottom: 0.5rem;
  color: #222;
}

.hero-thumbs {
  @extend .ut-hidden-print;
  @extend .uk-hidden-small;
  @extend .uk-flex;
  @extend .uk-flex-wrap;
}

.hero-thumb {
  @extend .uk-flex-item-none;
  box-sizing: border-box;
  width: 25%;
  padding-bottom: 0.2rem;
  padding-right: 0.2rem;
  border-color: $alpha-color !important;
  & > img {
    @extend .uk-border-rounded;
    width: 100%;
    height: auto;
    border: solid 1px white;
    opacity: 0.75;
  }
}

.hero-thumb--active {
  & > img {
    @include box-shadow;
    border-color: #000;
    opacity: 1;
  }
}
